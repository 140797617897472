const questions = {
  payment: [
    {
      question: "What is TravelbyBit?",
      answer: `<p>
        TravelbyBit is an Australian-based travel booking agency that
        allows you to pay for your travel services, such as flights,
        accommodation, cruises, tours, and more, in a range of
        cryptocurrencies and traditional payment methods.
      </p>`,
    },
    {
      question: "What payment processor do you use?",
      answer: `<p>
        TravelbyBit is a registered digital currency exchange
        provider, which allows us to convert cryptocurrency to fiat
        currency. Therefore, we do not have to rely on a third party
        payment processor to process any purchases made in digital
        currency.
      </p>`,
    },
    {
      question: "How do cryptocurrency payments work?",
      answer: `<ul>
        <li>
          1. Search for flights or accommodation on the TravelbyBit
          website.
        </li>
        <li>
          2. Select the flight(s) or hotel(s) that you would like to
          purchase.
        </li>
        <li>
          3. Review your booking before continuing to the next page
          and enter the required details.
        </li>
        <li>
          4. At the Confirm page, select any of the cryptocurrency
          payment options under Payment Details.
        </li>
        <li>
          5. After reviewing your itinerary details, click Book and a
          pop-up window will appear. The pop-up page will display the
          amount due, a QR code and wallet address.
        </li>
        <li>
          6. Scan the QR code with your mobile wallet and follow the
          prompts in your wallet to send the transaction. If you are
          using a wallet that does not have QR code scanning
          functionality, such as a desktop wallet, copy the address
          into the send function of the exchange or desktop wallet,
          along with the exact requested amount in
          <b>cryptocurrency</b>, and send the transaction.
        </li>
        <li>
          7. A green tick will appear on the TravelbyBit website if
          your transaction is successful. You will receive an
          automatic confirmation email shortly after your payment has
          been sent.
        </li>
        <li>
          8. Once your booking has been processed, you will receive
          your purchased travel products via a separate email.
        </li>
      </ul>`,
    },
    {
      question: "Can I cancel my booking?",
      answer: `<p>
        All cancellations are processed in accordance with the various
        policies of the airline/supplier. Please note that some
        bookings are non-refundable and you will not be able to
        receive a refund for non-refundable bookings.
      </p>
      <p>
        Where a cancellation to a booking is made, administration
        processing fees may be applied on top of supplier related
        charges. Domestic cancellations attract a AUD 55
        administration fee and international cancellations attract a
        AUD 200 administration fee.
      </p>
      <p>
        Depending on the travel supplier's cancellation policy, reason
        for cancellation, and type of booking, you may be able to
        apply your booking value toward a future booking in the form
        of TravelbyBit Travel Credits.
      </p>
      <p>
        If you need to cancel your booking, please send us a message
        via the <a href="/contact-us">Contact Us</a> page so that we
        may review your booking conditions and discuss your options.
      </p>`,
    },
    {
      question: "How do I obtain cryptocurrency?",
      answer: `<p>
        You can purchase cryptocurrency from a reputable online
        exchange. If you are based in Australia, you can use the
        <a href="https://binancelite.com/">Binance Lite</a> platform to purchase cryptocurrency with cash.
        Please ensure you have sufficient funds to pay for your
        booking before attempting to make a booking.
      </p>`,
    },
    {
      question:
        "How do I know the total amount of cryptocurrency I will have to pay?",
      answer: `<p>
      After you have selected the cryptocurrency you wish to pay in, the payment will automatically convert and display both the amount of cryptocurrency due and the equivalent value in fiat currency.
      </p>`,
    },
    {
      question: "How long does it take to confirm my payment?",
      answer: `<p>
      Once you have sent a cryptocurrency payment, a tick will be displayed and the window will update with your booking reference. You will receive an email confirmation of your booking, however, your travel products will not be immediately available to you until the cryptocurrency has been received by us. The time required for the payment to be confirmed will be dependent on the cryptocurrency used, the network conditions of the associated blockchain, and the miner fee set by you. A second email will be sent to you once your payment is received by us, which includes your itinerary and details of your transaction.
      </p>`,
    },
    {
      question: "What currency will I be charged in?",
      answer: `<p>
      All transactions are charged in Australian dollars. Since TravelbyBit is based in Australia, your bank may impose an international fee on credit card transactions from outside of Australia. Please note that these fees are outside of TravelbyBit’s control.
      </p>`,
    },
    {
      question: "Are there any fees when paying by credit or debit card??",
      answer: `<p>
      Yes. A 1.5% surcharge is incurred on all credit and debit card payments to cover the cost of the transaction.
      </p>`,
    },
    {
      question:
        "Why does my wallet show ‘Invalid Address’ after scanning the invoice QR code?",
      answer: `<p>
        The QR codes generated by our system incorporates the amount
        so that you don’t have to manually enter it. However, some
        wallets are not compatible with these types of QR codes, known
        as Payment Protocol QR codes. To bypass this, copy and paste
        the address and amount in cryptocurrency below the QR code
        into your wallet payment feature.
      </p>`,
    },
    {
      question: "How do I know what to set the miner fee to?",
      answer: `<p>
        Miner fees are used to incentivise miners to confirm your
        transaction on the blockchain. The higher the miner fee, the
        faster your transaction will be confirmed. Some wallets allow
        you to manually choose your miner fee, while others have
        pre-set options such as slow, normal and fast. Generally, your
        wallet will automatically set a regular speed mining fee which
        is sufficient in times of normal network demand. When the
        network is in high demand, miner fees will need to be higher
        in order to confirm transactions faster. Each coin has
        different mining fees, so there is no set rule for what is an
        ideal mining fee. To see average historical mining fees of
        some popular cryptocurrencies, see
        <a
          href="https://bitinfocharts.com/comparison/bitcoin-transactionfees.html"
          target="_new"
        >
          
          https://bitinfocharts.com/comparison/bitcoin-transactionfees.html
        </a>
      </p>`,
    },
    {
      question: "I made a payment but I don’t see a tick?",
      answer: `<p>
        If you have made a payment and the screen does not show a
        green tick before your session expires, please DO NOT attempt
        to resend the amount. Doing so could result in payment being
        made twice. Instead, <a href="/contact-us">contact</a> the
        TravelbyBit support team.
      </p>
      <p>
        Transactions made in periods of heavy network congestion may
        require more time to process. Please allow for additional time
        during these periods before contacting support staff.
      </p>`,
    },
    {
      question:
        "I received a tick when I made the payment but I haven’t received an email?",
      answer: `<p>
        If you received a green tick upon payment but have not
        received an email confirmation within an hour, please
        <a href="/contact-us">contact us</a>
      </p>`,
    },
    {
      question:
        "What happens if my session expires before my transaction reaches the network?",
      answer: `<p>
        If you have sent a transaction moments before your session
        expired and did not receive either a tick immediately after
        making the payment, or a confirmation email within an hour of
        making the payment, DO NOT attempt to make the payment again.
        Instead, please <a href="contact-us">contact us</a>
      </p>`,
    },
    {
      question:
        "I made a payment but the screen shows a cross instead of a tick?",
      answer: `<p>
        A red cross indicates that only a partial payment has been
        received. This could occur for a number of reasons:
      </p>
      <ul>
        <li>
          1. Your wallet or exchange account had insufficient funds
          but still sent out part of the requested amount.
        </li>
        <li>
          2. The cryptocurrency amount sent did not match the
          requested amount on the invoice.
        </li>
        <li>
          3. The fiat value was entered when sending instead of the
          equivalent cryptocurrency value.
        </li>
      </ul>
      <br />
      <p>
        You will receive an email stating that a partial payment has
        been received. The email will contain instructions on how you
        can complete your payment for the outstanding amount.
      </p>`,
    },
    {
      question: "How long do I have before my session expires?",
      answer: `<p>
        Once you have reached the Confirm stage and proceeded to make
        your payment, the payment session will be valid for 15
        minutes.
      </p>`,
    },
    {
      question:
        "What happens if my payment session expires and I haven’t paid?",
      answer: `<p>
        If your payment session expires before you have sent your
        payment, simply close the pop-up window and click Book at the
        bottom of the main session screen.
      </p>`,
    },
    {
      question: "Can I combine payment methods?",
      answer: `<p>
      No. Only Travel Credits can be combined with either a single cryptocurrency or credit card 
  transaction. You cannot combine a credit card payment with a cryptocurrency payment. You also 
  cannot combine multiple cryptocurrency payment options, for example, a booking cannot be 
  purchased by sending half the price in bitcoin and half the price in Litecoin.
      </p>`,
    },
  ],
  travel: [
    {
      question: "Do I need travel insurance?",
      answer: `<p>
          Travel insurance is highly recommended for all international
          and domestic travel. In some cases, travel insurance is
          compulsory. Australian residents can contact us to obtain a
          travel insurance quote.
        </p>
        <p>
          All other travellers can speak to their local providers to
          obtain travel insurance quotes, as our travel consultants may
          only provide general advice with regards to non-Australian
          travel insurance.
        </p>`,
    },
    {
      question: "Do I need a visa?",
      answer: `<p>
              It is each traveller’s sole responsibility to ensure they have
              the necessary visas, passports and travel documentation for
              all destinations of travel.
          </p>
          <p>
              TravelbyBit consultants are authorised to provide general
              advice to Australian passport holders only. Please refer to
              the respective consulates/embassies for more information.
          </p>`,
    },
    {
      question: "Do I need a passport or other identification?",
      answer: `<p>
          The identification you need depends on whether you are flying
          domestically or internationally.
        </p>
        <p>
          When travelling on a domestic flight, you may need to show
          photographic identification, but in most cases you will not
          need to show a passport.
        </p>
        <p>
          You will always need your passport for international flights.
        </p>`,
    },
    {
      question: "What is my baggage allowance?",
      answer: `<p>
          Your included checked baggage allowance is determined by your
          itinerary. For checked baggage allowance information, and to
          avoid any excess baggage charges, please refer to your
          travel/booking documentation or the airline's website.
        </p>`,
    },
    {
      question: "Can I cancel my booking?",
      answer: `<p>
          All cancellations are processed in accordance with the various
          policies of the airline/supplier. Please note that some
          bookings are non-refundable and you will not be able to
          receive a refund for non-refundable bookings.
        </p>
        <p>
          Where a cancellation to a booking is made, administration
          processing fees may be applied on top of supplier related
          charges. Domestic cancellations attract a AUD 55
          administration fee and international cancellations attract a
          AUD 200 administration fee.
        </p>
        <p>
          Depending on the travel supplier's cancellation policy, reason
          for cancellation, and type of booking, you may be able to
          apply your booking value toward a future booking in the form
          of TravelbyBit Travel Credits.
        </p>
        <p>
          If you need to cancel your booking, please send us a message
          via the <a href="/contact-us">Contact Us</a> page so that we
          may review your booking conditions and discuss your options.
        </p>`,
    },
    {
      question: "Can I change my booking?",
      answer: `<p>
          All changes are be processed in accordance with the various
          policies of the airline/supplier.
        </p>
        <p>
          Please <a href="/contact-us">contact us</a> and provide us
          with your booking reference number to find out what changes
          are permitted to your booking.
        </p>
        <p>
          Voluntary changes to travel bookings will incur a TravelbyBit
          administration fee of AUD 22 (domestic Australian travel) and
          AUD 75 (international travel) per passenger per booking, in
          addition to supplier fees.
        </p>
        <p>
          Depending on the travel supplier's change policy, reason for
          change, and booking type, you may be able to apply your
          booking value towards a future booking in the form of
          TravelbyBit Travel Credits.
        </p>`,
    },
    {
      question: "Can I change the name on my airline ticket?",
      answer: `<p>
          Some airlines will allow you to change the name on your
          reservation because of a spelling mistake but it is NOT
          possible to change the name of a different passenger.
        </p>
        <p>
          In some cases before travel has commenced, name corrections
          are permitted for the below reasons only:
        </p>
        <ol>
          <li>1. Misspelt name for up to 3 characters</li>
          <li>2. Changing from a married/maiden name</li>
          <li>3. Juxtaposed names</li>
          <li>
            4. Change of title/gender (eg. booked as MR and changing to
            MISS).
          </li>
        </ol>
        <br />
        <p>
          Please <a href="/contact-us">contact us</a> as soon as
          possible if you find an error on your flight itinerary. Name
          change reissues may also incur an airline service fee in
          accordance with the supplier's name change policy.
        </p>`,
    },
    {
      question: "What is a schedule change?",
      answer: `<p>
          Schedule changes to your flight can happen for many reasons -
          including airport runway works or unplanned aircraft
          maintenance.
        </p>
        <p>
          If the airline reschedules your flight before you are due to
          travel, we will do our best to contact you using the relevant
          contact details provided in your booking.
        </p>
        <p>
          If you are not available and your voicemail is active, we will
          leave you a message or send you the new flight details to the
          email address you provided for the original booking.
        </p>`,
    },
    {
      question: "What happens if my airline cancels my flight?",
      answer: `<p>
          There are times for every airline when things don't go to
          plan. If this is the case, we will work with the airline and
          try to get you to your destination on the next available
          flight or arrange another flight option for you on another
          airline.
        </p>
        <p>
          Please note that cancellations initiated by travel service
          providers are outside of TravelbyBit's control and we are not
          liable for the consequences of any cancellations made by these
          third parties.
        </p>`,
    },
    {
      question: "Do I have to book an airline ticket for my child?",
      answer: `<p>
          Yes. ALL passengers require a valid e-ticket with a confirmed
          booking reference, including infants and children.
        </p>`,
    },
    {
      question: "Can I add my frequent flyer number when making my booking?",
      answer: `<p>
          Yes. You can add your frequent flyer information when you
          enter your name and other details.
        </p>
        <p>
          If you have missed adding your frequent flyer details when
          making your booking, please contact us before your travel and
          we will generally be able to fix this for you.
        </p>
        <p>
          If you do not inform us of your frequent flyer number until
          after you have travelled, you will need to contact the airline
          directly. Please note that you will need to quote the airline
          booking reference by referring to your travel documentation
          and will have to provide a copy of your boarding pass.
        </p>`,
    },
    {
      question: "When can I check-in to my hotel room?",
      answer: `<p>
      Check in is usually 2pm local time. Please check your documentation or contact the hotel directly to 
      find out more. Some hotels do not have 24-hour reception so please confirm with us if you are going 
      to be arriving late.
        </p>`,
    },
    {
      question: "When do I have to check-out of my hotel room?",
      answer: `<p>
      The check out time is usually 10am local time. Please check your documentation or contact the hotel 
      directly to find out more.
        </p>`,
    },
    {
      question: "What happens if I don’t show up to my hotel reservation?",
      answer: `<p>
          You have the right to keep your reservation regardless of when
          you check in. For example, if you have a 7 night booking and
          voluntarily decide to only utilise 4 nights, the other 3
          nights for the room are forfeited.
        </p>
        <p>
          However, if you fail to notify the hotel prior to amending
          your stay, all nights can be forfeit and the hotel reserves
          the right to resell your room to another guest.
        </p>`,
    },
  ],
  rewards: [
    {
      question: "What is the Crypto Rewards Program?",
      answer: `<p>
      TravelbyBit’s Crypto Rewards Program allows you to earn rewards of up to 5% in bitcoin on eligible bookings made on travelbybit.com. For every US$10 you spend on a booking, you will receive one (1) Status Point. As you accumulate more Status Points over, your Status will increase once you have enough to progress to the next Status Tier. There are five Status Tiers in total, with different levels of rewards for flight and accommodation bookings within each Status Tier:</p>
    <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Titanium</th>
                <th>Platinum</th>
                <th>Gold</th>
                <th>Silver</th>
                <th>Bronze</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rewards per hotel booking</td>
                <td>5%</td>
                <td>4%</td>
                <td>3%</td>
                <td>2%</td>
                <td>1%</td>
              </tr>
              <tr>
                <td>Rewards per flight booking</td>
                <td>$10.50</td>
                <td>$5.50</td>
                <td>$3.50</td>
                <td>$2.50</td>
                <td>$1.50</td>
              </tr>
              <tr>
                <td>Status Required</td>
                <td>9000</td>
                <td>3500</td>
                <td>1000</td>
                <td>200</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Status Maintenance</td>
                <td>7200</td>
                <td>2800</td>
                <td>800</td>
                <td>160</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
      
      `,
    },
    {
      question: `What is the difference between Status Points and Status Tiers?`,
      answer: `Status Points are accumulated by purchasing flight and accommodation bookings, which contribute 
    to your progression to the next Status Tier. The more Status Points you acquire, the closer you get to 
    upgrading your Status Tier to unlock greater rewards!`,
    },
    {
      question: `How long are Status Points valid for?`,
      answer: `Status Points are valid from the day the booking was made and expire 12 months after the day of booking. For example, if you made a booking on June 7, 2019 for US$1000 (and therefore earned 100 Status Points), the Status Points earned from this bookings contribute to your Status until June 7, 2020, after which they will no longer be current and will not contribute to your Status.`,
    },
    {
      question: `What is Status Maintenance?`,
      answer: `The Status Maintenance outlines the amount of Status Points required to maintain your Status once it has been attained. In order to maintain your Status, you must attain the required Status Points over a 12 month period under the Status Maintenance requirements. For example, once you reach Silver Badger Status (200 Status Points) by purchasing bookings equal to or greater than US$2000 within a 12 month period, you will only need to maintain 160 Status Points over subsequent 12 month periods to maintain the Silver Badger Status.`,
    },
    {
      question: `What happens if I drop below the Status Maintenance?`,
      answer: `If the Status Points you accumulate over a 12 month period do not meet the Status Maintenance, 
    your Status will be reverted to the Status that corresponds to the attained Status Points under the 
    Status Maintenance during that Benefit Period.`,
    },
    {
      question: `Why am I still receiving rewards from the previous Status Tier?`,
      answer: `When you make a booking that upgrades you to the next Status Tier, that booking will be entitled to 
    receive the rewards of your Status at the time the booking was made. You will only receive the 
    rewards associated with the upgraded Status for your next booking.`,
    },
    {
      question: `When will I receive my rewards?`,
      answer: `Rewards will be credited to your account after you have boarded your flight or completed your stay 
    at the accommodation provider. Your Status Points, however, will be credited on the date of your 
    booking.`,
    },
    {
      question: `Will I still receive my rewards if I’ve cancelled my booking?`,
      answer: `If you cancel your booking, you will not receive any rewards. Any Status Points credited to your 
      account as a result of that booking will be reversed.`,
    },
    {
      question: `When can I withdraw my rewards?`,
      answer: `You can withdraw your rewards once your bitcoin reward balance is greater than US$25.`,
    },
    {
      question: `Do I earn rewards when I pay with Travel Credits? `,
      answer: `Yes! Any Travel Credits you use will be treated exactly the same as other payment method, 
      regardless of whether the Travel Credits cover some or all of the booking.`,
    },
  ],
  travelCredits: [
    {
      question: "What are Travel Credits?",
      answer:
        "<p>Travel Credits are automatically used to redeem travel products advertised on <a href='travelbybit.com'>https://travelbybit.com</a>. One Travel Credit is equivalent to one Australian Dollar.</p>",
    },
    {
      question: "How do I obtain Travel Credits?",
      answer: `<p>Travel Credits can be obtained in three ways:</p>
              <ul>
                <li>Promotions - You can obtain Travel Credits by participating in promotions.</li>
                <li>Refunds - You can be refunded in Travel Credits for cancellations to your travel bookings purchased using cryptocurrency.</li>
                <li>Purchases - You can purchase Travel Credits with selected cryptocurrencies. This will allow you to swap your cryptocurrency for an equivalent fiat value amount to use on future bookings with TravelbyBit..</li>
              </ul>`,
    },
    {
      question: "Where can I view my Travel Credit balance?",
      answer:
        "To view your Travel Credit balance, go to the Account page and click the ‘Travel Credit’ tab.",
    },
    {
      question: "How do I use my Travel Credits?",
      answer:
        "Travel Credits will be redeemed at the check-out page automatically if the booking is eligible for discount using Travel Credits. The booking price must be equivalent to or greater than US$150 in order for the Travel Credits to be applied to the booking.",
    },
    {
      question: "What happens if I cancel my booking?",
      answer:
        "If you cancel or forfeit a booking, the Travel Credits that have been applied to that booking will be forfeited and cannot be redeemed for another booking.",
    },
    {
      question: "Do my Travel Credits have an expiry date?",
      answer:
        "Yes. Travel Credits are valid for twelve (12) months from the date of issuance.",
    },
    {
      question: "Do I earn rewards when I pay with Travel Credits?",
      answer:
        "Yes! Any Travel Credits you use will be treated exactly the same as other payment method, regardless of whether the Travel Credits cover some or all of the booking.",
    },
    {
      question: "Can I transfer my Travel Credits to someone else?",
      answer:
        "No. Travel Credits can only be used by the person who was originally credited with the Travel Credits.",
    },
    {
      question: "Can I book travel for someone else using my travel credits?",
      answer:
        "Yes. Just like any other booking, you will be able to select the number of passengers that you are making the booking for and will be able to purchase the booking using Travel Credits.",
    },
  ],
}

export default questions
