import React, { useState } from "react"
import styled from "styled-components"
import queryString from "query-string"
import SEO from "../components/seo"

import { Bar, Item } from "../components/ui/tabs"
import Faq from "../components/faq"

export default function FaqPage({ location }) {
  const query = queryString.parse(location.search)
  const [selected, setSelected] = useState(query.selected ? query.selected : "travel")
  return (
    <>
      <SEO title="FAQ" />
      <Page>
        <div className="container">
          <h1>Frequently Asked Questions</h1>

          <p className="header-text">
            We value our customers and appreciate everyone has a choice in their
            travel arrangements.
            <br />
            We believe you are entitled to expect professional and courteous
            service at all points of contact with us.
          </p>
        </div>
        <StyledBar>
          <StyledContainer>
            <StyledItem
              selected={selected === "travel"}
              onClick={() => setSelected("travel")}
            >
              <h3>TRAVEL</h3>
            </StyledItem>
            <StyledItem
              selected={selected === "payment"}
              onClick={() => setSelected("payment")}
            >
              <h3>PAYMENT</h3>
            </StyledItem>
            <StyledItem
              selected={selected === "rewards"}
              onClick={() => setSelected("rewards")}
            >
              <h3>REWARDS</h3>
            </StyledItem>
            <StyledItem
              selected={selected === "travelCredits"}
              onClick={() => setSelected("travelCredits")}
            >
              <h3>TRAVEL CREDITS</h3>
            </StyledItem>
          </StyledContainer>
        </StyledBar>
        <div className="container">
          <div className="content">
            <Faq question={selected} />
          </div>
        </div>
      </Page>
    </>
  )
}

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem 0 1rem;
  }

  .header-text {
    margin: 0.5rem 0 2rem 0;
    line-height: 1.625;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }

  .content {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.07);
    padding: 2rem 2rem;
    margin: 2rem 0 3rem 0;
  }

  ul {
    list-style: none;
  }
`

const StyledContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1170px;

  @media only screen and (max-width: 417px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const StyledBar = styled(Bar)`
  width: 100%;
`

const StyledItem = styled(Item)`
  padding: 1rem 3rem;
`
