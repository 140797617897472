import React, { useState } from "react"
import Collapse, { Panel } from "rc-collapse"
import styled from "styled-components"
import parse from "html-react-parser"
import "rc-collapse/assets/index.css"

import questions from "../data/faq"
import theme from "../theme"

export default function Faq({ question }) {
  const [activeKey, setActiveKey] = useState([])

  const data = questions[question]

  const onChange = key => {
    setActiveKey(key)
  }

  return (
    <Collapse
      accordion={false}
      defaultActiveKey="1"
      onChange={onChange}
      activeKey={activeKey}
    >
      {data.map((question, i) => (
        <StyledPanel
          headerClass="question"
          header={question.question}
          key={i}
          showArrow={false}
        >
          {parse(question.answer)}
        </StyledPanel>
      ))}
    </Collapse>
  )
}

const StyledPanel = styled(Panel)`
  line-height: 1.625;
  .question {
    &:hover {
      color: ${theme.orange} !important;
    }
  }

  table {
    color: #424242;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 30px;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    width: 100%;

    th,
    td {
      padding: 10px 5px;
      border: 1px solid #aaa;
      width: 15%;

      &:first-child {
        width: 20%;
      }
    }

    th {
      background: #f4f5f7;
    }

    td {
      &:first-child {
        font-weight: bold;
      }
    }
  }
`
